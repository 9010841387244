import { NextPage } from 'next';

import Page404Component from 'app/components/common/Page404Component';
import AppLayout from 'app/components/layout/AppLayout';

const Page404: NextPage = () => (
  <AppLayout>
    <Page404Component />
  </AppLayout>
);

export default Page404;
