import React from 'react';
import styled from '@emotion/styled';

import Breadcrumbs, { BreadcrumbsProps } from 'app/components/common/Breadcrumbs';
import Title from 'app/components/common/Title';

const PageTitle: React.FC<React.PropsWithChildren<BreadcrumbsProps>> = ({ breadcrumbs, children }) => {
  return (
    <PageTitleWrapper>
      <Title variant='h1'>{children}</Title>
      <BreadcrumbsWrapper>
        {breadcrumbs && breadcrumbs.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      </BreadcrumbsWrapper>
    </PageTitleWrapper>
  );
};

export default PageTitle;

const PageTitleWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0, 2, 0)};
`;

const BreadcrumbsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
