import React from 'react';
import styled from '@emotion/styled';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import Link from 'next/link';

import TextEllipsis from 'app/components/common/TextEllipsis';
import { colors } from 'app/theme-colors';
import { Route } from 'app/utils/constants';

export interface BreadcrumbItemProps {
  title?: string;
  url?: Route;
}

export interface BreadcrumbsProps {
  breadcrumbs?: BreadcrumbItemProps[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, ...props }) => {
  if (typeof breadcrumbs === 'undefined') return null;

  return (
    <MuiBreadcrumbs aria-label='breadcrumb' {...props}>
      {breadcrumbs.map((item, idx) => {
        if (item.url) {
          return (
            <Link key={idx} href={item.url} passHref>
              <StyledBCLink>{item.title}</StyledBCLink>
            </Link>
          );
        }

        return <StyledTypography key={idx}>{item.title}</StyledTypography>;
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;

const StyledBCLink = styled.a`
  display: block;
  color: ${colors.text.secondary} !important;
  max-width: 200px;
  ${TextEllipsis}
`;

const StyledTypography = styled(Typography)`
  max-width: 400px;
  ${TextEllipsis}
`;
